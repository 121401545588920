import * as metrics from '@citadel/tools/metrics';
import { decodeQueryParams } from '@citadel/tools/utils/queryParams';
import {
  BridgeApiPublicInterface,
  BridgeInitConfig,
  BridgeOAuthResultData,
  BridgeTokenResponse,
  BridgeWidgetInitConfigMessageData,
  BridgeWidgetMessage,
  BridgeWidgetMessageType,
  CategorizedCompaniesResponseRaw,
  CloseData,
  ExternalLoginSuccessData,
  FlowMessage,
  OriginQueryParams,
  PublicMessageEventData,
  SingleBridgeGuardFunction,
  TruvBridgeMessage,
} from 'citadel-types';

import { flowUrlClassic, flowUrlNew } from './common/flowUrl';
import { OpenedFrame } from './OpenedFrame';
import isBrowserSupported from './utils/isBrowserSupported';

type WindowParams = {
  origin: string;
  queryParams: OriginQueryParams;
};

type OpenConfig = {
  additionalInfo?: BridgeWidgetInitConfigMessageData['additionalInfo'];
};

type OpenedBridge = {
  frame: OpenedFrame;
  windowParams: WindowParams;
};

export class BridgeApi implements BridgeApiPublicInterface {
  private additionalInfo: OpenConfig['additionalInfo'];
  private openedBridge: OpenedBridge | null;

  constructor(
    private readonly config: BridgeInitConfig,
    private readonly singleBridgeGuard: SingleBridgeGuardFunction,
  ) {
    this.additionalInfo = undefined;
    this.openedBridge = null;

    if (config.autoOpen) {
      this.open();
    } else if (!config.bridgeToken) {
      // eslint-disable-next-line no-console
      console.error('bridgeToken is required');
    }
  }

  private filterAndGroupQueryParams(queryParamsRaw: Record<string, string | string[]>) {
    const flags: Record<string, string> = {};
    const queryParams: Record<string, string | string[]> = {};

    Object.keys(queryParamsRaw).forEach((key) => {
      const value = queryParamsRaw[key];

      if (key.startsWith('ab') || key.startsWith('ff')) {
        if (value && typeof value === 'string') {
          flags[key] = value;
        }
      } else if (!key.startsWith('TRUV_TEST')) {
        queryParams[key] = value;
      }
    });

    return {
      flags,
      queryParams,
    };
  }

  private initIframe() {
    const releaseSingleBridgeGuard = this.singleBridgeGuard();

    if (this.openedBridge || releaseSingleBridgeGuard === null) {
      return;
    }

    const { origin, search } = new URL(window.document.location.href);
    const { flags, queryParams } = this.filterAndGroupQueryParams(this.config.params ?? decodeQueryParams(search));

    const params = new URLSearchParams();

    params.append('bridge_token', this.config.bridgeToken);
    params.append('is_mobile_app', this.additionalInfo?.isMobileApp ? 'true' : 'false');

    Object.keys(flags).forEach((key) => params.append('flag_' + key, flags[key]));
    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];

      if (Array.isArray(value)) {
        value.forEach((singleValue) => params.append('param_' + key, singleValue));
      } else {
        params.append('param_' + key, value);
      }
    });

    const position = this.config.position ?? { type: 'dialog' };

    let newFlowUsed = false;
    let availableMessageReceived = false;

    let activeSrc;

    if (flowUrlNew) {
      params.set('iframe-mode', 'new');
      newFlowUsed = true;
      activeSrc = `${flowUrlNew}?${params.toString()}`;
    } else {
      params.set('iframe-mode', 'classic');
      activeSrc = `${flowUrlClassic}?${params.toString()}`;
    }

    this.openedBridge = {
      frame: OpenedFrame.createAndShow(position, {
        iframeSrc: activeSrc,
        title: 'Truv',
        id: 'citadel-widget-flow',
        onMessage: (event: MessageEvent<TruvBridgeMessage>) => {
          if (event.data.type === FlowMessage.Available) {
            availableMessageReceived = true;
          }

          this.handleMessage(event);
        },
        onClose: releaseSingleBridgeGuard,
      }),
      windowParams: {
        origin,
        queryParams,
      },
    };

    const openedBridge = this.openedBridge;

    setTimeout(() => {
      if (newFlowUsed && !availableMessageReceived) {
        params.set('iframe-mode', 'fallback');
        openedBridge.frame.changeIframeSrc(`${flowUrlClassic}?${params.toString()}`);
      }
    }, 1500);
  }

  private handleMessage = (e: MessageEvent<TruvBridgeMessage>) => {
    const payload = e.data;

    if (!payload?.type) {
      return;
    }

    const { bridgeToken, clientName, product, trackingInfo, onEvent, onLoad, onSuccess } = this.config;

    if (!this.openedBridge) {
      return;
    }

    switch (payload.type) {
      case FlowMessage.Close:
        const { data: closeData } = payload;

        this.close(closeData);

        break;
      case FlowMessage.Event:
        const { data: eventData } = payload;

        if (eventData) {
          const { payload, type } = eventData as PublicMessageEventData; // HACK, we don't show not public events here

          onEvent?.(type, payload);

          if (type === 'OPEN') {
            metrics.send({
              name: 'widget_total_open_time',
              type: 'gauge',
              value: Date.now() - this.openedBridge.frame.openTimestamp,
            } as const);
          }
        }
        break;
      case FlowMessage.Onload:
        this.sendMessage({
          source: 'bridge',
          data: {
            bridgeToken,
            clientName,
            product,
            trackingInfo,
            origin: this.openedBridge.windowParams.origin,
            additionalInfo: this.additionalInfo,
            queryParams: this.openedBridge.windowParams.queryParams,
          },
          type: BridgeWidgetMessageType.Init,
        });
        onEvent?.('LOAD', { bridge_token: this.config.bridgeToken });
        onLoad?.();
        break;
      case FlowMessage.Success:
        const { data: successData } = payload;

        if (!successData) {
          throw new Error('data is missing in context');
        }

        const { taskId, publicToken, employer } = successData;

        if (!publicToken) {
          throw new Error('publicToken is missing in context.data');
        }

        onSuccess?.(publicToken, {
          task_id: taskId,
          employer,
        });

        this.close(employer ? { employer } : undefined);

        break;
      case FlowMessage.Available:
        // Ignore this message, we need it only for initial csp error check
        break;
      case FlowMessage.TitleChange:
        this.openedBridge.frame.changeIframeTitle(payload.data.title);
        break;
      case FlowMessage.OpenExternalLink:
        this.config.internal?.onExternalLinkProcessor?.(payload.data.url);
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const e: never = payload;
    }
  };

  private sendMessage = (payload: BridgeWidgetMessage) => {
    if (!this.openedBridge) {
      throw new Error('openedBridge object is not found');
    }

    this.openedBridge.frame.postMessage(payload);
  };

  open(config: OpenConfig = {}) {
    if (!isBrowserSupported()) {
      this.config.onEvent?.('UNSUPPORTED_BROWSER', { bridge_token: this.config.bridgeToken });
      this.config.onClose?.();

      return;
    }

    if (!this.config.bridgeToken) {
      // eslint-disable-next-line no-console
      console.error('bridgeToken is required');
      this.config.onClose?.();

      return;
    }

    const newAdditionalInfo = {
      ...this.config.additionalInfo,
      ...config.additionalInfo,
      originalReferrer: window.document.referrer,
    };

    if (this.config.internal?.onExternalLinkProcessor) {
      newAdditionalInfo.hasExternalLinkProcessor = true;
    }

    this.additionalInfo = newAdditionalInfo;
    this.initIframe();
  }

  close(closeData?: CloseData) {
    if (!this.openedBridge) {
      return;
    }

    this.openedBridge.frame.dispose();
    this.openedBridge = null;

    this.config.onEvent?.('CLOSE', { ...(closeData ?? {}), bridge_token: this.config.bridgeToken });
    this.config.onClose?.();
  }

  setBridgeTokenResponse(data: BridgeTokenResponse) {
    this.sendMessage({
      source: 'bridge',
      type: BridgeWidgetMessageType.SetBridgeTokenResponse,
      data,
    });
  }

  setCategorizedCompaniesResponse(data: CategorizedCompaniesResponseRaw) {
    this.sendMessage({
      source: 'bridge',
      type: BridgeWidgetMessageType.SetCategorizedCompaniesResponse,
      data,
    });
  }

  back() {
    this.sendMessage({
      source: 'bridge',
      type: BridgeWidgetMessageType.GoBack,
    });
  }

  onExternalLoginSuccess(data: ExternalLoginSuccessData) {
    this.sendMessage({ source: 'bridge', type: BridgeWidgetMessageType.ExternalLoginSuccess, data });
  }

  onExternalLoginCancel() {
    this.sendMessage({ source: 'bridge', type: BridgeWidgetMessageType.ExternalLoginCancel });
  }

  onOAuthResult(data: BridgeOAuthResultData) {
    this.sendMessage({ source: 'bridge', type: BridgeWidgetMessageType.BridgeOAuthResult, data });
  }
}
