const ADDITIONAL_SCALE = 1.05;

export const EMULATOR_HEADER_HIGHT = 32;
export const EMULATOR_FOOTER_HIGHT = 70;

export const MIN_DESKTOP_WIDGET_HEIGHT = 576;

const MIN_DESKTOP_WIDGET_WIDTH = 324;

export const MAX_DESKTOP_WIDGET_HEIGHT = 736;

const MIN_EMULATOR_DESKTOP_WIDGET_FULL_HEIGHT =
  MIN_DESKTOP_WIDGET_HEIGHT + EMULATOR_FOOTER_HIGHT + EMULATOR_HEADER_HIGHT;

export const getWidgetFullHeightWithAdditionalScale = (isEmulator: boolean) =>
  (!isEmulator ? MIN_DESKTOP_WIDGET_HEIGHT : MIN_EMULATOR_DESKTOP_WIDGET_FULL_HEIGHT) * ADDITIONAL_SCALE;

export const getDesktopWidgetHeightByScreenSize = (screenHeight: number, isEmulator: boolean) => {
  const SUM_SECTIONS_HEIGHT = isEmulator ? EMULATOR_HEADER_HIGHT + EMULATOR_FOOTER_HIGHT : 0;

  return Math.min(
    MAX_DESKTOP_WIDGET_HEIGHT,
    Math.max(MIN_DESKTOP_WIDGET_HEIGHT, screenHeight / ADDITIONAL_SCALE - SUM_SECTIONS_HEIGHT),
  );
};

export const getDesktopWidgetWidthByWidgetHeight = (widgetHeight: number) =>
  (widgetHeight / MIN_DESKTOP_WIDGET_HEIGHT) * MIN_DESKTOP_WIDGET_WIDTH;

export const MAX_DESKTOP_WIDGET_WIDTH = getDesktopWidgetWidthByWidgetHeight(MAX_DESKTOP_WIDGET_HEIGHT);

export const SMALL_SCREEN_WIDTH_PX = 576;

export const WIDGET_BORDER_RADIUS_PX = 16;
